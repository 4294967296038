import { CommonServerSideParams } from '@/app/types/CommonServerSideParams';
import IntroCard from '@/common/components/IntroCard';
import MiniDrawer from '@/common/components/menu/MiniDrawer';
import { OnlyBrowserPageProps } from '@/layouts/core/types/OnlyBrowserPageProps';
import { SSGPageProps } from '@/layouts/core/types/SSGPageProps';
import PublicLayout from '@/layouts/public/components/PublicLayout';
import { getPublicLayoutStaticPaths, getPublicLayoutStaticProps } from '@/layouts/public/publicLayoutSSG';
import { createLogger } from '@/modules/core/logging/logger';
import { useUser } from '@auth0/nextjs-auth0';
import { Box, Button, Container, Link, Typography } from '@mui/material';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import router from 'next/router';

const fileLabel = 'pages/[locale]/public/index';
const logger = createLogger({
  // eslint-disable-line no-unused-vars,@typescript-eslint/no-unused-vars
  fileLabel,
});

/**
 * Only executed on the server side at build time.
 * Necessary when a page has dynamic routes and uses "getStaticProps".
 */
export const getStaticPaths: GetStaticPaths<CommonServerSideParams> = getPublicLayoutStaticPaths();

/**
 * Only executed on the server side at build time.
 *
 * @return Props (as "SSGPageProps") that will be passed to the Page component, as props.
 *
 * @see https://github.com/vercel/next.js/discussions/10949#discussioncomment-6884
 * @see https://nextjs.org/docs/basic-features/data-fetching#getstaticprops-static-generation
 */
export const getStaticProps: GetStaticProps<SSGPageProps, CommonServerSideParams> = getPublicLayoutStaticProps();

/**
 * SSG pages are first rendered by the server (during static bundling).
 * Then, they're rendered by the client, and gain additional props (defined in OnlyBrowserPageProps).
 * Because this last case is the most common (server bundle only happens during development stage), we consider it a default.
 * To represent this behaviour, we use the native Partial TS keyword to make all OnlyBrowserPageProps optional.
 *
 * Beware props in OnlyBrowserPageProps are not available on the server.
 */
type Props = {} & SSGPageProps<Partial<OnlyBrowserPageProps>>;

/**
 * Main public page.
 */
const MainPublicPage: NextPage<Props> = (props): JSX.Element => {
  const [error] = useState(null);
  const { user, isLoading } = useUser();

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <PublicLayout
        {...props}
        pageName='TTT CryptoScore'
        headProps={{
          seoTitle: 'TTT CryptoScore',
          seoDescription:
            'The dashboard page displays cryptos with an opiniated score from our Crypto team, based on data from Coingecko and miscellaneous sources.',
        }}
      >
        <Container>
          <MiniDrawer />
          <IntroCard>
            <h1>Welcome to the TTT CryptoScore</h1>
            <Typography>
              <strong>The CryptoScore</strong> is an application provided by <strong>The Trading Team LTD</strong>.
            </Typography>
            <Typography>
              Behind the scene, it uses the data of <strong>several public APIs</strong> and gathers them in a
              meaningful way to you, crypto traders.
            </Typography>
            <hr />
            {!user &&
              <Typography>
                The CryptoScore now needs a username and password to access the different screens. You have 2 ways to
                get access now:
                <ul>
                  <li>You may <Button variant='contained' onClick={() => {
                    router.push('/payment');
                  }}>subscribe to the premium subscription</Button></li>
                  <li>You may <Link href='https://www.thetradingteam.net/contact'>contact us</Link> to get a free access
                    if you were part of the beta testers.
                  </li>
                </ul>
              </Typography>
            }
            {
              user &&
              <Typography>
                You can access your Stripe dashboard here to manage your subscription or get access to your bills.<br />
                You will need to enter your e-mail address to access to the subscription dashboard.<br />
                <Button variant='contained' onClick={() => {
                  window.open('https://billing.stripe.com/p/login/test_fZe7sudDB2AI2t2000');
                }}>Access to the Subscription Dashboard</Button>.
              </Typography>
            }
          </IntroCard>
        </Container>
        <Box mb={5}>
          <Container>
            <h2>FAQ</h2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Question</TableCell>
                    <TableCell>Answer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <p>What is the purpose of the recurring donation? What will the amount of my subscription be
                        used for?</p>
                    </TableCell>
                    <TableCell>
                      <p>Every amount collected will be useful for all future projects supported by TTT because your
                        subscription amount will be added to the traded funds and it is the profits from trading that
                        will be used to finance projects. You have all the details on <a href='https://www.thetradingteam.net/le-don-durable-notre-originalite/'>our page</a>.
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>Can I download my invoices?</p>
                    </TableCell>
                    <TableCell>
                      <p>When you are logged in, you have access to your subscription management tool. That's where
                        you can download your invoices.</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>How can I reset my password?</p>
                    </TableCell>
                    <TableCell>
                      <p>Below the login form, there is a "Forgot password" link that will send you a code via email
                        to validate.</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>What is the minimum subscription duration?</p>
                    </TableCell>
                    <TableCell>
                      <p>There is no minimum commitment duration. After your monthly payment, your subscription is
                        valid until the same day of the following month (for example, if you paid on the 24th, you
                        will be charged on the 24th of the following month). You are free to stop the subscription at
                        any time when you no longer need it.</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>How can I cancel my subscription?</p>
                    </TableCell>
                    <TableCell>
                      <p>When you are logged in, you have access to your subscription management tool. That's where
                        you can cancel with one click.</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>Can I reuse the CryptoScore data for my own community?</p>
                    </TableCell>
                    <TableCell>
                      <p>Yes of course! To help us get known, we ask you then to create a clickable link to our
                        website.</p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <p>I'm new. How do I start?</p>
                    </TableCell>
                    <TableCell>
                      <p>A good starting point is the Top 10 Dashboard section which is accessible from the left menu.
                        You will find a selection of the best scores from the global dashboard. We suggest hovering
                        over the numbers with the mouse to understand how the scores are calculated. We also suggest
                        using the marketcap filters above the table (Marketcap &lt; 200M$).
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
        <Container>
          <h2>Changelog</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Version</TableCell>
                  <TableCell>Features</TableCell>
                  <TableCell>Bugfixes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <p>Version 5.0.246 - 03/02/2023</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Updated the FAQs</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Fixed non working dashboard filters</li>
                    </ul>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Version 5.0.242 - 09/11/2022</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Added authentication</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Fixed non working dashboard filters</li>
                    </ul>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Version 5.0.239 - 02/07/2022</p>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Fixed Chainlink Ecosystem analysis</li>
                    </ul>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Version 5.0.237 - 05/05/2022</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Renamed Crypto Dashboard to CryptoScore</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p>Version 5.0.230 - 29/04/2022</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>2 new screens: Defillama TVL per chain or protocol!</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p>Version 5.0.226 - 08/03/2022</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Dashboard: Show real Twitter followers</li>
                      <li>Dashboard: Remove Defipulse</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p>Version 5.0.225 - 29/01/2022</p>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>SEO optimization: titles and descriptions</li>
                    </ul>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p>Version 5.0.221 - 27/01/2022</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Dashboard + top 10: Twitter followers count is now accurate</li>
                      <li>Dashboard + top 10: review of the score algorithm (split between fundamental and timing
                        scores)
                      </li>
                    </ul>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p>Version 5.0.219 - 17/01/2022</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Dashboard: remove Insurance, add Metaverse</li>
                      <li>Dashboard: add public notice</li>
                      <li>Chainlink ecosystem: add a voting system to remove false positives</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p>Version 5.0.215 - 20/12/2021</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Master IDs: display the matching reasons</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p>Version 5.0.212 - 13/12/2021</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Coingecko categories: add quick filters by market cap</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p>Version 5.0.208 - 10/12/2021</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Dashboard: add quick filters by market cap</li>
                      <li>Dashboard top 10 (with quick filters by market cap)</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <p>Version 5.0.202 - 09/12/2021</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Dashboard: add a total score (sum of CG and CMC scores)</li>
                      <li>Dashboard: each crypto has now a direct link to CG</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Dashboard and Chainlink: allow to sort while filtering columns without resetting the whole
                        table
                      </li>
                      <li>Configure Google analytics</li>
                    </ul>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <p>Version 5.0.201 - 05/12/2021</p>
                  </TableCell>
                  <TableCell>
                    <ul>
                      <li>Initial public version</li>
                    </ul>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </PublicLayout>
    );
  }
};

export default MainPublicPage;
